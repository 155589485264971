<template>
  <div>
    <div class="w-100 text-right">
      <modal-btn
        btn-class="btn-sm btn-soft-danger space-x-2"
        target="#add-insurance"
        title="إضافة شريحة تأمين"
      >
        <span>إضافة</span>
        <i class="fa fa-plus"></i>
      </modal-btn>
    </div>
    <div class="table-responsive shadow">
      <table class="table table-bordered">
        <tr class="bg-bb">
          <th>يدفع</th>
          <th>يزاود لغاية</th>
          <th>تعديل</th>
        </tr>
        <tr v-if="emptyList">
          <td colspan="3" class="font-weight-bolder">لا يوجد</td>
        </tr>
        <insurance-item
          v-else
          @deleted="deleted"
          @update="update"
          v-for="item in list"
          :key="item.id"
          :item="item"
        ></insurance-item>
      </table>
    </div>
    <ThePaginator
      :total="total"
      :url="defaultUrl"
      :last-page="lastPage"
      :links="links"
    />
    <teleport to="#app">
      <confirm-model
        title="إضافة شريحة تأمين"
        confirm-text="إضافة"
        confirm-btn="bg-bb"
        header-bg="bg-bb"
        target-id="add-insurance"
        title-color="text-dark"
        @confirmed="clickById('add-insurance-btn')"
      >
        <form @submit.prevent="store">
          <div class="row">
            <div class="col-sm-3">يدفع</div>
            <div class="col-sm-9">
              <form-input
                id="price"
                type="number"
                placeholder="يدفع"
              ></form-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">يزاود لغاية</div>
            <div class="col-sm-9">
              <form-input
                id="max"
                type="number"
                placeholder="يزاود لغاية"
              ></form-input>
            </div>
          </div>
          <button hidden id="add-insurance-btn"></button>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import ThePaginator from "@/components/UI/ThePaginator";
import InsuranceItem from "@/components/Pages/AuctionsLists/InsuranceItem";
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormInput from "@/components/Forms/FormInput";
import arrays from "@/common/arrays";

export default {
  components: {
    FormInput,
    ConfirmModel,
    ModalBtn,
    InsuranceItem,
    ThePaginator,
  },
  mixins: [pagination],
  computed: {
    defaultUrl() {
      return "/insurances";
    },
    storageName() {
      return "insurances";
    },
  },
  methods: {
    async store(e) {
      const response = await http.send("insurances", new FormData(e.target));
      if (http.responseAccepted(response, "data")) {
        this.setPagination(response.data);
        this.hideModal("#add-insurance");
        $_(".form-control").val("");
      } else if (http.responseAccepted(response, "msg")) {
        http.popupMessage(
          !!response.data.type ? response.data.type : "info",
          response.data.msg
        );
      }
    },
    async update(e) {
      const formData = new FormData(e.target);
      const id = formData.get("id");
      http.inputsId = id;

      const response = await http.send(`insurances/${id}`, formData);
      if (http.responseAccepted(response)) {
        this.setResponseData(response, `#insurance-edit${id}`);
      }
      http.inputsId = "";
    },
    deleted(id) {
      this.list = arrays.removeById(this.list, id);
    },
    setResponseData(response, modal) {
      if (!response) return false;

      this.setPagination(response.data);
      this.hideModal(modal);
      http.successMessage(messages.saved);
    },
  },
  async created() {
    this.storage = true;
    await this.setList();
  },
};
</script>