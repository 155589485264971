<template>
  <tr v-if="!!item" :id="rowId">
    <td><span class="link">{{ item.price }}</span>&nbsp;دينار</td>
    <td><span class="link">{{ item.max }}</span>&nbsp;دينار</td>
    <td>
      <modal-btn btn-class="btn-sm btn-primary mr-1" :target="`#${editModalId}`">
        تعديل
      </modal-btn>
      <modal-btn btn-class="btn-sm btn-soft-danger" :target="`#${deleteModalId}`">
        حذف
      </modal-btn>
    </td>
    <teleport to="#app">
      <confirm-model
        :target-id="editModalId"
        title="تعديل شريحة تأمين"
        confirm-text="تعديل"
        @confirmed="clickById(`submit-item${item.id}`)">
        <form @submit.prevent="update">
          <input type="hidden" name="id" :value="item.id">
          <input type="hidden" name="_method" value="PUT">
          <div class="row">
            <div class="col-sm-3">يدفع</div>
            <div class="col-sm-9">
              <form-input :id="`price${item.id}`" name="price" type="number" :value="item.price" placeholder="يدفع"></form-input>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">يزاود لغاية</div>
            <div class="col-sm-9">
              <form-input :id="`max${item.id}`" name="max" type="number" :value="item.max" placeholder="يزاود لغاية"></form-input>
            </div>
          </div>
          <button hidden :id="`submit-item${item.id}`"></button>
        </form>
      </confirm-model>
      <items-destroyer
        :target-id="deleteModalId"
        :url="`insurances/${item.id}`"
        :row-id="rowId"
        @deleted="deleted">
        <span>هل أنت متأكد من حذف شريحة المزاد</span>&nbsp;<span class="link">{{ item.price }}</span> ؟
      </items-destroyer>
    </teleport>
  </tr>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import FormInput from "@/components/Forms/FormInput";

export default {
  components: {FormInput, ItemsDestroyer, ConfirmModel, ModalBtn},
  props: {item: {required: true}},
  emits: ['deleted', 'update'],
  computed: {
    editModalId() {
      if (!this.item)
        return '';
      return `insurance-edit${this.item.id}`;
    },
    deleteModalId() {
      if (!this.item)
        return '';
      return `insurance-delete${this.item.id}`;
    },
    rowId() {
      if (!this.item)
        return '';
      return `insurance-row${this.item.id}`;
    }
  },
  methods: {
    update(e) {
      return this.$emit('update', e);
    },
    deleted(id) {
      return this.$emit('deleted', id);
    }
  }
}
</script>